import { GET_TEAMMATES, UPDATE_STORE_ID, VALIDATE_TOKEN } from '../action-types'
import api, { BE_API } from '../../common/api'
import { handleServerError } from '../../common/utils'

export const validateToken = token => async dispatch => {
  try {
    const res = await api.user.authenticate(token)
    BE_API.defaults.headers.Authorization = `Bearer: ${token}`
    return dispatch({ type: VALIDATE_TOKEN, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const updateStoreId = storeId => {
  return { type: UPDATE_STORE_ID, payload: storeId }
}

export const getTeammates = () => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.user.teammates(storeId)
    dispatch({ type: GET_TEAMMATES })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}