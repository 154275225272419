import styled from 'styled-components'
import { Layout } from 'antd'
import { colors } from '../../common/constants'

export const StyledFooter = styled(Layout.Footer)`
  text-align: center;
  padding: 0;
  height: 32px;
  color: ${colors.grey3};
  background: ${colors.white};
`
