import { useCallback, useState } from 'react'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Badge, Drawer, Input, Select, Space } from 'antd'
import { FilterOutlined } from '@ant-design/icons'

import {
  colors,
  FILTER_DEPARTMENT,
  FILTER_ORDER_NUMBER,
  FILTER_SOURCE,
  isMobile
} from 'src/common/constants'
import { Filter, FilterButtonsWrapper, FilterLabel, StyledSelect } from './styled'
import { UppercaseButton } from 'src/common/styled'
import { omit } from 'ramda'

const Filters = props => {
  const { intl, departments, sources, filters, onChange, onApply } = props
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const filtersCount = Object.keys(filters).length
  const hasFilters = !isEmpty(filters)

  const openDrawer = useCallback(() => setIsDrawerOpen(true), [])
  const closeDrawer = useCallback(() => setIsDrawerOpen(false), [])

  const onChangeFilters = useCallback((filter, value) => {
    let newFilters = {}
    if (value) {
      newFilters = { ...filters, [filter]: value }
    } else {
      newFilters = omit([filter], filters)
    }

    onChange(newFilters)
  }, [filters, onChange])

  const onApplyFilters = useCallback(() => {
    onApply(filters)
    closeDrawer()
  }, [filters, onApply, closeDrawer])

  const onResetFilters = useCallback(() => onChange({}), [onChange])

  const renderDrawerActions = useCallback(
    () => (
      <Space>
        {hasFilters && (
          <UppercaseButton type={'text'} onClick={onResetFilters}>
            <FormattedMessage id={'filters.button.reset'} />
          </UppercaseButton>
        )}
        <UppercaseButton type={'primary'} onClick={onApplyFilters}>
          <FormattedMessage id={'filters.button.apply'} />
        </UppercaseButton>
      </Space>
    ),
    [hasFilters, onResetFilters, onApplyFilters]
  )

  return (
    <>
      <FilterButtonsWrapper hasFilters={hasFilters}>
        <UppercaseButton
          type={'primary'}
          ghost
          icon={
            hasFilters ? (
              <Badge
                count={filtersCount}
                style={{ backgroundColor: colors.main }}
                className={'anticon'}
              />
            ) : (
              <FilterOutlined />
            )
          }
          onClick={openDrawer}>
          <span>
            {hasFilters ? (
              <FormattedMessage id={'filters.button.editFilters'} />
            ) : (
              <FormattedMessage id={'filters.button.applyFilters'} />
            )}
          </span>
        </UppercaseButton>
        {hasFilters && (
          <UppercaseButton type={'text'} onClick={onResetFilters}>
            <FormattedMessage id={'filters.button.resetFilters'} />
          </UppercaseButton>
        )}
      </FilterButtonsWrapper>
      <Drawer
        title={intl.formatMessage({ id: 'filters.title' })}
        placement={isMobile ? 'bottom' : 'left'}
        visible={isDrawerOpen}
        extra={renderDrawerActions()}
        onClose={closeDrawer}>
        <Filter>
          <FilterLabel>
            <FormattedMessage id={'filters.label.orderNumber'} />
          </FilterLabel>
          <Input
            placeholder={intl.formatMessage({
              id: 'filters.placeholder.orderNumber'
            })}
            value={filters[FILTER_ORDER_NUMBER]}
            onChange={e => onChangeFilters(FILTER_ORDER_NUMBER, e.target.value)}
          />
        </Filter>
        <Filter>
          <FilterLabel>
            <FormattedMessage id={'filters.label.departments'} />
          </FilterLabel>
          <StyledSelect
            placeholder={intl.formatMessage({
              id: 'filters.placeholder.departments'
            })}
            disabled={isEmpty(departments)}
            value={filters[FILTER_DEPARTMENT]}
            onChange={value => onChangeFilters(FILTER_DEPARTMENT, value)}>
            {departments.map(d => (
              <Select.Option key={d.departmentId}>
                {d.departmentName}
              </Select.Option>
            ))}
          </StyledSelect>
        </Filter>
        <Filter>
          <FilterLabel>
            <FormattedMessage id={'filters.label.sources'} />
          </FilterLabel>
          <StyledSelect
            placeholder={intl.formatMessage({
              id: 'filters.placeholder.sources'
            })}
            disabled={isEmpty(sources)}
            value={filters[FILTER_SOURCE]}
            onChange={value => onChangeFilters(FILTER_SOURCE, value)}>
            {sources.map(source => (
              <Select.Option key={source}>{source}</Select.Option>
            ))}
          </StyledSelect>
        </Filter>
      </Drawer>
    </>
  )
}
export default injectIntl(Filters)
