import React from 'react'
import { LikeFilled } from '@ant-design/icons'
import { StyledFeedbackButton } from './styled'

const FeedbackButton = props => {
  const { onClick } = props
  return (
    <StyledFeedbackButton
      type={'primary'}
      size={'small'}
      shape={'round'}
      onClick={onClick}>
      <LikeFilled />
      {'Feedback'}
    </StyledFeedbackButton>
  )
}
export default FeedbackButton
