const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)

const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-cancelled-orders-nprd-ping-4cb662axha-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'http://localhost:8080'
  },
  test: {
    LOGIN_URL: `https://test-lmit-cancelled-orders-nprd-ping-4cb662axha-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://test-cancelled-orders-api-4cb662axha-ew.a.run.app'
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-cancelled-orders-prd-ping-wcutspuiya-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://prod-cancelled-orders-api-wcutspuiya-ew.a.run.app'
  },
}

export default envConfig[env]
