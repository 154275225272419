import { useEffect, useState } from 'react'
import { isMobile } from 'src/common/constants'
import Loader from 'src/components/Loader'

const Dashboard = props => {
  const { history, location } = props
  const [isLoading, setIsLoading] = useState(true)

  const style = {
    width: '100%',
    height: 'calc(100vh - 144px)',
    display: isLoading ? 'none' : 'block',
    margin: '0 auto'
  }

  useEffect(() => {
    if (isMobile) history.push('/')
  }, [history, location])

  return <>
    {isLoading ? <Loader /> : null}
    <iframe
      title={'dashboard'}
      src={'https://datastudio.google.com/embed/reporting/bb5482b6-f4d8-4a1f-86b0-06800a2ed23c/page/VDnbC'}
      frameBorder={'0'}
      style={style}
      allowFullScreen
      onLoad={() => setIsLoading(false)}
    />
  </>
}

export default Dashboard