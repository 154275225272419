import {
  ASSIGN_PREDICTIONS,
  GET_DEPARTMENTS,
  GET_PREDICTIONS,
  GET_SOURCES,
  GET_STORES,
  MANAGE_PREDICTION
} from '../action-types'
import api from '../../common/api'
import { handleServerError } from 'src/common/utils'

export const getStores = () => async dispatch => {
  try {
    const res = await api.stores.list()
    dispatch({ type: GET_STORES })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const getPredictions = type => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.predictions.list(type, storeId)
    dispatch({ type: GET_PREDICTIONS })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const getDepartments = type => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.predictions.departments(type, storeId)
    dispatch({ type: GET_DEPARTMENTS })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const getSources = type => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.predictions.sources(type, storeId)
    dispatch({ type: GET_SOURCES })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const assignPredictions = (orderNumbers, assignedTo) => async (dispatch, getState) => {
  const { storeId } = getState().user
  const body = {
    storeId,
    orderNumbers,
    assignedTo
  }
  try {
    const { status } = await api.predictions.assign(body)
    dispatch({ type: ASSIGN_PREDICTIONS })
    return { status }
  } catch (error) {
    return handleServerError(error)
  }
}

export const managePrediction = (orderNumber, storeId, notes) => async dispatch => {
  const body = { storeId, notes }
  try {
    const { status } = await api.predictions.manage(orderNumber, body)
    dispatch({ type: MANAGE_PREDICTION })
    return { status }
  } catch (error) {
    return handleServerError(error)
  }
}