import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Link } from 'react-router-dom'

import Menu from '../Menu'
import User from '../User'
import { HeaderBar, StyledHeader } from './styled'
import logo from '../../assets/img/logo.png'

import { isMobile } from 'src/common/constants'

const Header = props => {
  const { user, changeStore } = props
  return (
    <>
      <HeaderBar />
      <StyledHeader>
        {isMobile && (user.isAdmin || user.isManager) ? (
          <Link to={'/'}>
            <img src={logo} alt={'logo'} height={32} />
          </Link>
        ) : (
          <img src={logo} alt={'logo'} height={32} />
        )}
        {!isMobile && user.isEnabled ? Menu({ user }) : <br />}
        {!isEmpty(user) && <User user={user} onClick={user.isAdmin ? changeStore : null} />}
      </StyledHeader>
    </>
  )
}
Header.propTypes = {
  user: PropTypes.object
}
export default Header
