import axios from 'axios'
import config from './config'

export const BE_API = axios.create({
  baseURL: config.API_URL,
  headers: {
    'API-Key': 'CA903EA7-BF7F-46AA-A3B9-22D8D791859C'
  }
})

const apis = {
  user: {
    authenticate: token => BE_API.get('/user', { headers: { Authorization: `Bearer ${token}` } }),
    teammates: storeId => BE_API.get('/teammates', { params: { storeId } })
  },
  stores: {
    list: () => BE_API.get('/stores')
  },
  predictions: {
    list: (type, storeId) => BE_API.get('/predictions', { params: { type, storeId } }),
    departments: (type, storeId) => BE_API.get('/departments', { params: { type, storeId } }),
    sources: (type, storeId) => BE_API.get('/sources', { params: { type, storeId } }),
    assign: body => BE_API.patch('/predictions', body),
    manage: (orderNumber, body) => BE_API.patch(`/predictions/${orderNumber}`, body)
  }
}
export default apis