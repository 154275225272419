// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const UPDATE_STORE_ID = 'UPDATE_STORE_ID'
export const GET_TEAMMATES = 'GET_TEAMMATES'

export const GET_STORES = 'GET_STORES'
export const GET_PREDICTIONS = 'GET_PREDICTIONS'
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const GET_SOURCES = 'GET_SOURCES'

export const ASSIGN_PREDICTIONS = 'ASSIGN_PREDICTIONS'
export const MANAGE_PREDICTION = 'MANAGE_PREDICTION'