import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import { message, Modal, Select, Tabs } from 'antd'

import { connect } from 'react-redux'
import { getTeammates } from 'src/redux-store/actions/user-actions'
import { getPredictions, getDepartments, getSources, assignPredictions } from 'src/redux-store/actions/app-actions'

import Filters from 'src/components/Filters'
import ItemsList from 'src/components/ItemsList'
import { ActionsRow, UppercaseButton } from 'src/common/styled'
import { TYPE_TO_ASSIGN, ASSIGNMENT_TYPES } from 'src/common/constants'

const Assignment = props => {
  const { intl, history, user, getTeammates, getPredictions, getDepartments, getSources, assignPredictions } = props

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(TYPE_TO_ASSIGN)

  const [teammates, setTeammates] = useState([])
  const [predictions, setPredictions] = useState([])
  const [departments, setDepartments] = useState([])
  const [sources, setSources] = useState([])

  const [filters, setFilters] = useState({})
  const [filteredItems, setFilteredItems] = useState(undefined)
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    if (!user.isManager && !user.isAdmin) return history.push('/management')
  }, [history, user])

  const getData = useCallback(async type => {
    setIsLoading(true)
    if (type === TYPE_TO_ASSIGN) {
      const teammates = await getTeammates()
      setTeammates(teammates)
    }
    const predictions = await getPredictions(type)
    const departments = await getDepartments(type)
    const sources = await getSources(type)

    setPredictions(predictions)
    setDepartments(departments)
    setSources(sources)
    setIsLoading(false)
  }, [getTeammates, getPredictions, getDepartments, getSources])

  useEffect(() => {
    getData(activeTab)
  }, [activeTab, user.storeId, getData])

  const onTabChange = useCallback(activeKey => {
    setIsLoading(true)
    setFilteredItems(undefined)
    setFilters({})
    setPredictions([])
    setActiveTab(activeKey)
  }, [])

  const onFiltersChange = useCallback(filters => setFilters(filters), [])
  const onFiltersApply = useCallback(() => {
    const filteredItems = predictions.filter(item => {
      const filterKeys = Object.keys(filters)
      const conditions = filterKeys.map(key => filters[key] ? item[key].toString() === filters[key] : true)
      return conditions.every(condition => condition === true)
    })
    setFilteredItems(filteredItems)
  }, [predictions, filters])

  useEffect(() => {
    if (isEmpty(filters)) onFiltersApply()
  }, [filters, onFiltersApply])

  const onItemSelect = useCallback((orderNumber, value) => {
    if (value) return setSelectedItems(prevItems => [...prevItems, orderNumber])
    return setSelectedItems(prevItems => prevItems.filter(item => item !== orderNumber))
  }, [])

  const openTeammatesModal = useCallback(() => {
    let selectedTeammate

    return Modal.confirm({
      title: intl.formatMessage({ id: 'teammatesModal.title' }),
      content: (
        <Select
          showSearch
          optionFilterProp={'children'}
          style={{ width: '100%' }}
          placeholder={intl.formatMessage({ id: 'teammatesModal.select.placeholder' })}
          onChange={value => selectedTeammate = value}>
          {teammates.map(t => <Select.Option key={t.id}>{t.displayName}</Select.Option>)}
        </Select>),
      okText: intl.formatMessage({ id: 'teammatesModal.okText' }),
      cancelText: intl.formatMessage({ id: 'teammatesModal.cancelText' }),
      onOk: async () => {
        setIsLoading(true)

        const { status } = await assignPredictions(selectedItems, selectedTeammate)
        setSelectedItems([])

        if (status === 200)
          message.success(intl.formatMessage({ id: 'message.success.assignPredictions' }), 5)

        getData(activeTab)
      }
    })
  }, [intl, activeTab, teammates, selectedItems, assignPredictions, getData])


  return <>
    <Tabs defaultActiveKey={activeTab} centered onChange={onTabChange} >
      {ASSIGNMENT_TYPES.map(type => (
        <Tabs.TabPane tab={<FormattedMessage id={`assignment.tab.${type}`} />} key={type} disabled={isLoading}>
          <ActionsRow>
            {Filters({ departments, sources, filters, onChange: onFiltersChange, onApply: onFiltersApply })}
            {type === TYPE_TO_ASSIGN && (
              <UppercaseButton type={'primary'} disabled={isEmpty(selectedItems)} onClick={openTeammatesModal}>
                <FormattedMessage id={'assignment.button.assignTo'} />
              </UppercaseButton>
            )}
          </ActionsRow>
          {ItemsList({ isLoading, type, items: filteredItems || predictions, selectedItems, onItemSelect })}
        </Tabs.TabPane>
      ))}
    </Tabs>
  </>
}

const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = {
  getTeammates,
  getPredictions,
  getDepartments,
  getSources,
  assignPredictions
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Assignment))