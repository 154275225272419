import { Select } from 'antd'
import styled from 'styled-components'

export const FilterButtonsWrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, max-content);
  @media screen and (max-width: 600px) {
    grid-template-columns: ${props => props.hasFilters ? '2fr 1fr' : '1fr'};
  }
`
export const Filter = styled.div`
  display: grid;
  gap: 8px;
  grid-template-rows: repeat(2, auto);
  margin-bottom: 16px;
`
export const FilterLabel = styled.strong`
  text-transform: uppercase;
`
export const StyledSelect = styled(Select)`
  width: 100%;
`