import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Input, message, Modal, Tabs } from 'antd'

import { connect } from 'react-redux'
import { getDepartments, getPredictions, getSources, managePrediction } from 'src/redux-store/actions/app-actions'

import Filters from 'src/components/Filters'
import ItemsList from 'src/components/ItemsList'
import { ActionsRow } from 'src/common/styled'
import { TYPE_TO_MANAGE, MANAGEMENT_TYPES } from 'src/common/constants'

const Management = props => {
  const { intl, user, getPredictions, getDepartments, getSources, managePrediction } = props

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(TYPE_TO_MANAGE)

  const [predictions, setPredictions] = useState([])
  const [departments, setDepartments] = useState([])
  const [sources, setSources] = useState([])

  const [filters, setFilters] = useState({})
  const [filteredItems, setFilteredItems] = useState(undefined)

  const getData = useCallback(async type => {
    setIsLoading(true)
    const predictions = await getPredictions(type)
    const departments = await getDepartments(type)
    const sources = await getSources(type)

    setPredictions(predictions)
    setDepartments(departments)
    setSources(sources)
    setIsLoading(false)
  }, [getPredictions, getDepartments, getSources])

  useEffect(() => {
    getData(activeTab)
  }, [activeTab, user.storeId, getData])

  const onTabChange = useCallback(activeKey => {
    setIsLoading(true)
    setFilters({})
    setFilteredItems(undefined)
    setPredictions([])
    setActiveTab(activeKey)
  }, [])

  const onFiltersChange = useCallback(filters => setFilters(filters), [])
  const onFiltersApply = useCallback(() => {
    const filteredItems = predictions.filter(item => {
      const filterKeys = Object.keys(filters)
      const conditions = filterKeys.map(key => filters[key] ? item[key].toString() === filters[key] : true)
      return conditions.every(condition => condition === true)
    })
    setFilteredItems(filteredItems)
  }, [predictions, filters])

  useEffect(() => {
    if (isEmpty(filters)) onFiltersApply()
  }, [filters, onFiltersApply])

  const openManagementModal = useCallback((orderNumber, storeId) => {
    return Modal.confirm({
      title: intl.formatMessage({ id: 'managementModal.title' }),
      content: <>
        <Input.TextArea
          id={'management-textarea'}
          placeholder={intl.formatMessage({ id: 'managementModal.textarea.placeholder' })}
        />
      </>,
      okText: intl.formatMessage({ id: 'managementModal.okText' }),
      cancelText: intl.formatMessage({ id: 'managementModal.cancelText' }),
      onOk: async () => {
        setIsLoading(true)

        const notes = document.getElementById('management-textarea').value
        const { status } = await managePrediction(orderNumber, storeId, notes)

        if (status === 200)
          message.success(intl.formatMessage({ id: 'message.success.managePrediction' }, { orderNumber }), 5)

        getData(activeTab)
      },
    })
  }, [intl, activeTab, managePrediction, getData])

  return <>
    <Tabs defaultActiveKey={activeTab} centered onChange={onTabChange}>
      {MANAGEMENT_TYPES.map(type => (
        <Tabs.TabPane tab={<FormattedMessage id={`management.tab.${type}`} />} key={type} disabled={isLoading}>
          <ActionsRow>
            {Filters({ departments, sources, filters, onChange: onFiltersChange, onApply: onFiltersApply })}
          </ActionsRow>
          {ItemsList({ isLoading, type, items: filteredItems || predictions, onItemManage: openManagementModal })}
        </Tabs.TabPane>
      ))}
    </Tabs>
  </>

}

const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = {
  getPredictions,
  getDepartments,
  getSources,
  managePrediction
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Management))