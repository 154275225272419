import { FormattedMessage } from 'react-intl'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'

const CustomMenu = props => {
  const { user } = props
  const location = useLocation()

  const menuItems = [
    (user.isManager || user.isAdmin) && {
      key: '/assignment',
      label: (
        <Link to={'/assignment'}>
          <FormattedMessage id={'app.menuItem.assignment'} />
        </Link>
      )
    },
    {
      key: '/management',
      label: (
        <Link to={'/management'}>
          <FormattedMessage id={'app.menuItem.management'} />
        </Link>
      )
    },
    {
      key: '/dashboard',
      label: (
        <Link to={'/dashboard'}>
          <FormattedMessage id={'app.menuItem.dashboard'} />
        </Link>
      )
    }
  ]

  return (
    <Menu
      mode={'horizontal'}
      selectedKeys={[location.pathname]}
      style={{ lineHeight: '60px' }}
      items={menuItems} />
  )
}
export default CustomMenu
