import { Empty, List } from 'antd'
import Loader from '../Loader'
import ItemCard from '../ItemCard'

const ItemsList = props => {
  const { isLoading, type, items, selectedItems = [], onItemSelect, onItemManage } = props
  if (isLoading) return <Loader height={'300px'} />
  return (
    <List
      grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 6 }}
      locale={{ emptyText: <Empty description={null} /> }}
      pagination={{ pageSize: 12, hideOnSinglePage: true }}
      dataSource={items}
      renderItem={item => (
        <List.Item>
          {ItemCard({
            item,
            type,
            isSelected: selectedItems.includes(item.orderNumber),
            onSelect: onItemSelect,
            onManage: onItemManage
          })}
        </List.Item>
      )}
    />
  )
}
export default ItemsList
