import { useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { isMobile } from 'src/common/constants'
import { UppercaseButton } from 'src/common/styled'
import { ButtonsContainer } from './styled'

const Home = props => {
  const { history, location, user } = props
  useEffect(() => {
    if (!user.isManager && !user.isAdmin) return history.push('/management')
    if (!isMobile && (user.isManager || user.isAdmin)) return history.push('/assignment')
  }, [history, location, user])

  const goTo = useCallback(location => history.push(location), [history])

  return isMobile ? (
    <ButtonsContainer>
      <UppercaseButton type={'primary'} size={'large'} onClick={() => goTo('/assignment')}>
        <FormattedMessage id={'home.button.assignment'} />
      </UppercaseButton>
      <UppercaseButton type={'primary'} size={'large'} onClick={() => goTo('/management')}>
        <FormattedMessage id={'home.button.management'} />
      </UppercaseButton>
    </ButtonsContainer>
  ) : (
    ''
  )
}

const mapStateToProps = state => ({
  user: state.user
})
export default connect(mapStateToProps)(Home)