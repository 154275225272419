import { useCallback } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Checkbox } from 'antd'
import * as AntdIcons from '@ant-design/icons'
import { ContentWrapper, ContentField, ContentFieldIcon, ContentFieldLabel, StyledCard } from './styled'
import { ITEM_CARD_FIELDS, ITEM_CARD_ICONS, TYPE_TO_ASSIGN, TYPE_TO_MANAGE } from 'src/common/constants'
import { UppercaseButton } from 'src/common/styled'

const ItemCard = props => {
  const { intl, item, type, isSelected, onSelect, onManage } = props
  const { orderNumber, storeId } = item

  const onClickCallback = useCallback(() => onSelect(orderNumber, !isSelected), [isSelected, orderNumber, onSelect])

  const renderCardExtra = useCallback(() => {
    if (type === TYPE_TO_ASSIGN)
      return <Checkbox checked={isSelected} />
    if (type === TYPE_TO_MANAGE)
      return <UppercaseButton type={'link'} size={'small'} onClick={() => onManage(orderNumber, storeId)}>
        <FormattedMessage id={'management.button.manage'} />
      </UppercaseButton>
  }, [type, orderNumber, storeId, isSelected, onManage])

  return <StyledCard
    title={intl.formatMessage(
      { id: 'card.label.orderNumber' },
      { orderNumber: orderNumber }
    )}
    size={'small'}
    extra={renderCardExtra()}
    onClick={onClickCallback}>
    <ContentWrapper>
      {ITEM_CARD_FIELDS[type].map(field => {
        const FieldIcon = AntdIcons[ITEM_CARD_ICONS[field]]
        return item[field] ? (
          <ContentField key={field}>
            <ContentFieldIcon><FieldIcon /></ContentFieldIcon>
            <ContentFieldLabel>
              <FormattedMessage id={`card.label.${field}`} /><br />
              <strong>{item[field]}</strong>
            </ContentFieldLabel>
          </ContentField>
        ) : ''
      })}
    </ContentWrapper>
  </StyledCard>
}
export default injectIntl(ItemCard)
