import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { injectIntl } from 'react-intl'
import { Switch, Route } from 'react-router-dom'
import { Result } from 'antd'
import { connect } from 'react-redux'

import { getStores } from 'src/redux-store/actions/app-actions'
import { updateStoreId } from 'src/redux-store/actions/user-actions'

import Auth from '../Auth'
import Home from '../Home'
import Assignment from '../Assignment'
import Management from '../Management'
import Dashboard from '../Dashboard'
import Page404 from '../404'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import FeedbackButton from 'src/components/FeedbackButton'
import StoreModal from 'src/components/StoreModal'
import { WhiteLayout, PaddedContent } from 'src/common/styled'

const App = props => {
  const { intl, user, getStoresAction, updateStoreIdAction } = props
  const [stores, setStores] = useState([])
  const [isStoreModalVisible, setIsStoreModalVisible] = useState(false)

  const isEmptyUser = isEmpty(user)

  const tangram = useRef(
    new window.Tangram({
      productId: 'f104225f-cdfb-4fa7-a7b2-605bc9d1c566', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.LDAP,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true
      }
    })
  )

  const getStores = useCallback(async () => {
    const stores = await getStoresAction()
    setStores(stores)
  }, [getStoresAction])

  useEffect(() => {
    if (user.isAdmin) {
      getStores()
      openStoreModal()
    }
  }, [isEmptyUser, user.isAdmin, getStores])

  const updateStoreId = storeId => {
    updateStoreIdAction(storeId)
    closeStoreModal()
  }

  const openStoreModal = () => setIsStoreModalVisible(true)
  const closeStoreModal = () => setIsStoreModalVisible(false)

  const renderUserIsNotEnabled = useCallback(() => {
    return (
      <Result
        status={'403'}
        title={intl.formatMessage({ id: 'app.user.isNotEnabled' })}
      />
    )
  }, [intl])

  return (
    <WhiteLayout>
      <Header user={user} changeStore={openStoreModal} />
      <Auth>
        <PaddedContent>
          <Switch>
            {!user.isEnabled ? (
              <Route render={() => renderUserIsNotEnabled()} />
            ) : null}
            <Route component={Home} exact path={'/'} />
            <Route component={Assignment} path={'/assignment'} />
            <Route component={Management} path={'/management'} />
            <Route component={Dashboard} path={'/dashboard'} />
            <Route component={Page404} />
          </Switch>
        </PaddedContent>
      </Auth>
      <Footer />
      <FeedbackButton onClick={() => tangram.current.open()} />
      <StoreModal
        stores={stores}
        isVisible={isStoreModalVisible}
        onCloseModal={closeStoreModal}
        onSelectStore={updateStoreId}
      />
    </WhiteLayout>
  )
}
App.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = {
  getStoresAction: getStores,
  updateStoreIdAction: updateStoreId
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App))
