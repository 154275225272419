import styled from 'styled-components'
import { Button, Layout } from 'antd'

import { colors } from './constants'

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 16px;
  min-height: calc(100vh - 112px);
`

export const UppercaseButton = styled(Button)`
  text-transform: uppercase;
`

export const ActionsRow = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr auto;
  margin-bottom: 16px;
  
  @media screen and (max-width: 600px) {
    grid-template-columns: auto;
  }
`