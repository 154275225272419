export const isMobile = window.innerWidth < 600

export const TYPE_TO_ASSIGN = 'TO_ASSIGN'
export const TYPE_ASSIGNED = 'ASSIGNED'
export const TYPE_TO_MANAGE = 'TO_MANAGE'
export const TYPE_ARCHIVE = 'ARCHIVE'

export const ASSIGNMENT_TYPES = [TYPE_TO_ASSIGN, TYPE_ASSIGNED]
export const MANAGEMENT_TYPES = [TYPE_TO_MANAGE, TYPE_ARCHIVE]

export const FILTER_ORDER_NUMBER = 'orderNumber'
export const FILTER_DEPARTMENT = 'departmentId'
export const FILTER_SOURCE = 'predictionSource'

export const ITEM_CARD_FIELD_CONTEXT_STORE = 'contextStoreId'
export const ITEM_CARD_FIELD_DEPARTMENT = 'departmentName'
export const ITEM_CARD_FIELD_ORDER_VALUE = 'orderValue'
export const ITEM_CARD_FIELD_SOURCE = 'predictionSource'
export const ITEM_CARD_FIELD_CREATED_BY = 'createdByFullName'
export const ITEM_CARD_FIELD_ASSIGNED_TO = 'assignedToFullName'
export const ITEM_CARD_FIELD_MANAGED_BY = 'managedByFullName'
export const ITEM_CARD_FIELD_NOTES = 'notes'

export const ITEM_CARD_ICONS = {
  [ITEM_CARD_FIELD_CONTEXT_STORE]: 'ShopOutlined',
  [ITEM_CARD_FIELD_DEPARTMENT]: 'HomeOutlined',
  [ITEM_CARD_FIELD_ORDER_VALUE]: 'EuroOutlined',
  [ITEM_CARD_FIELD_SOURCE]: 'FileSearchOutlined',
  [ITEM_CARD_FIELD_CREATED_BY]: 'UserOutlined',
  [ITEM_CARD_FIELD_ASSIGNED_TO]: 'UserOutlined',
  [ITEM_CARD_FIELD_MANAGED_BY]: 'UserOutlined',
  [ITEM_CARD_FIELD_NOTES]: 'EditOutlined'
}

export const ITEM_CARD_FIELDS = {
  [TYPE_TO_ASSIGN]: [
    ITEM_CARD_FIELD_CONTEXT_STORE,
    ITEM_CARD_FIELD_DEPARTMENT,
    ITEM_CARD_FIELD_ORDER_VALUE,
    ITEM_CARD_FIELD_SOURCE,
    ITEM_CARD_FIELD_CREATED_BY
  ],
  [TYPE_ASSIGNED]: [
    ITEM_CARD_FIELD_CONTEXT_STORE,
    ITEM_CARD_FIELD_DEPARTMENT,
    ITEM_CARD_FIELD_ORDER_VALUE,
    ITEM_CARD_FIELD_SOURCE,
    ITEM_CARD_FIELD_ASSIGNED_TO
  ],
  [TYPE_TO_MANAGE]: [
    ITEM_CARD_FIELD_CONTEXT_STORE,
    ITEM_CARD_FIELD_DEPARTMENT,
    ITEM_CARD_FIELD_ORDER_VALUE,
    ITEM_CARD_FIELD_SOURCE
  ],
  [TYPE_ARCHIVE]: [
    ITEM_CARD_FIELD_CONTEXT_STORE,
    ITEM_CARD_FIELD_DEPARTMENT,
    ITEM_CARD_FIELD_ORDER_VALUE,
    ITEM_CARD_FIELD_SOURCE,
    ITEM_CARD_FIELD_MANAGED_BY,
    ITEM_CARD_FIELD_NOTES
  ]
}
